import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  ButtonProps,
  Dialog as MuiDIalog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Typography,
} from '@mui/material'
import { fontSizes, fontWeights, lineHeights } from 'assets/styles'
import AppContext, { AppContextProps } from 'containers/context/Context'
import QRCode from 'qrcode.react'
import * as React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TranslationServices } from 'translateService'

interface DialogProps extends MuiDialogProps {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: {
    link: React.ReactNode
    buttons: ButtonProps[]
  }
}

// const [copied, setCopied] = React.useState(false)

export const ShareDialog = ({
  header,
  body,
  footer,
  ...props
}: DialogProps) => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)

  return (
    <MuiDIalog {...props} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" sx={{ padding: '0px' }}>
        {header}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '8px 12px !important',
        }}
      >
        {body}
      </DialogContent>
      {footer ? (
        <DialogActions
          sx={{
            padding: '8px 12px',
            justifyContent: 'space-between',
          }}
        >
          <Box overflow="hidden" marginBottom="25px">
            <Typography
              fontSize={fontSizes.fs_12}
              lineHeight={lineHeights.lh_18}
              fontWeight={fontWeights.regular}
              component="div"
              variant="inherit"
              align="left"
            >
              {TranslationServices.Translate('LINK_TO_SHARE')}:
            </Typography>
            <Box width="100%" display="flex">
              <Typography
                fontSize={fontSizes.fs_12}
                lineHeight={lineHeights.lh_18}
                fontWeight={fontWeights.bold}
                component="div"
                variant="inherit"
                align="left"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                sx={{
                  textDecorationLine: 'underline',
                }}
                width="90%"
                paddingTop="8px"
              >
                {footer?.link}
              </Typography>

              <CopyToClipboard
                text={footer?.link}
                onCopy={() =>
                  setTimeout(() => {
                    rootDispatch({
                      type: 'snackBar',
                      payload: {
                        open: true,
                        message: 'Copied to clipboard',
                      },
                    })
                  }, 200)
                }
              >
                <ContentCopyIcon style={{ color: '#2F53E6' }} />
              </CopyToClipboard>
            </Box>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
              }}
            >
              <QRCode value={`${footer?.link}`} renderAs="canvas" />
            </Typography>
            <Typography
              fontSize={fontSizes.fs_12}
              lineHeight={lineHeights.lh_18}
              fontWeight={fontWeights.regular}
              component="div"
              variant="inherit"
              align="center"
              marginTop="10px"
            >
              {TranslationServices.Translate('QR_CODE')}
            </Typography>
          </Box>
        </DialogActions>
      ) : null}
    </MuiDIalog>
  )
}
