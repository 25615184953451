export const palette = {
  common: {
    black: '#212121',
    white: '#fffff',
  },
  primary: {
    light: '#ffac33',
    main: '#FF9800',
    dark: '#b26a00',
    contrastText: 'rgba(255,255,255,0.87)',
  },
  secondary: {
    light: '#F72D2D',
    main: '#FF2979',
    dark: '#C91126',
  },
  grey: {
    600: '#878787',
  },
}