import LocationOnIcon from '@mui/icons-material/LocationOn'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ShareIcon from '@mui/icons-material/Share'
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  IconButtonProps,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import bgHeader from 'assets/images/header.png'
import { colors, fontSizes, fontWeights } from 'assets/styles'
import * as React from 'react'
const StyledAppBar = styled(AppBar)`
  padding: 10px 20px 0px 20px;
  .MuiToolbar-root {
    display: block;
    flex-wrap: wrap;
    min-height: unset;
    @media screen and (min-width: 600px) {
      max-width: 1200px;
      // min-width: 800px;
      margin: 0 auto;
      min-height: 68px;
      width: 100%;
      padding: 0px 24px;
    }
  }
`
export interface LandingHeaderProps {
  title?: string
  rightButton?: IconButtonProps
  contactButton?: IconButtonProps
  pdfButton?: IconButtonProps
  shareButton?: IconButtonProps
  badgeInvisible?: boolean
  productTitle?: string
  productAddress?: string
}
export const LandingHeader = ({
  // title = 'Hi there!',
  title = '',
  rightButton,
  contactButton,
  pdfButton,
  shareButton,
  badgeInvisible,
  productTitle,
  productAddress,
}: LandingHeaderProps) => {
  return (
    <StyledAppBar
      position="fixed"
      sx={{
        zIndex: 100,
        backgroundImage: `url("${bgHeader}") `,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Toolbar disableGutters>
        <Box display="flex" mb="10px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_16}
            fontWeight={fontWeights.medium}
            sx={{ flexGrow: 1, margin: 'auto', marginLeft: '20px' }}
          >
            {title}
          </Typography>
          {rightButton ? (
            <IconButton
              {...rightButton}
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: '6px',
              }}
            >
              <Badge color="secondary" variant="dot" invisible={badgeInvisible}>
                <MoreVertIcon />
              </Badge>
            </IconButton>
          ) : null}
        </Box>
        <Box
          width="100%"
          height="70px"
          display="flex"
          padding="10px 20px"
          sx={{
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            background: colors.gradientOrange50deg,
          }}
        >
          <Box sx={{ flexGrow: 1 }} display="block">
            <Typography
              variant="inherit"
              component="div"
              fontSize={fontSizes.fs_18}
              fontWeight={fontWeights.semibold}
              sx={{
                textShadow: '2px 2px#5d5b5b',
              }}
              mb="6px"
            >
              {productTitle}
            </Typography>
            <Typography
              variant="inherit"
              component="div"
              className="text-icon"
              fontSize={fontSizes.fs_10}
              fontWeight={fontWeights.regular}
            >
              <LocationOnIcon /> {productAddress}
            </Typography>
          </Box>
          <Box display="flex" margin="auto">
            <IconButton
              {...contactButton}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <PermContactCalendarIcon />
            </IconButton>
            <IconButton
              {...pdfButton}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <PictureAsPdfIcon />
            </IconButton>
            <IconButton
              {...shareButton}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: '-12px',
              }}
            >
              <ShareIcon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      {/* <Toolbar disableGutters>asd</Toolbar> */}
    </StyledAppBar>
  )
}
