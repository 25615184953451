import { Redirect } from 'react-router'
import LandingRoutes from 'containers/landing/Routes'
//! Redirect to Home page
const Root = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

//! Redirect to 404 page
const Error = [
  {
    component: <Redirect to={'/404-not-found'} />,
  },
]
//! Error component must be at the last item in an array.
const RootRoutes = [...LandingRoutes, ...Root, ...Error]

export default RootRoutes
