import { Button } from '@mui/material'
import { colors, fontSizes, fontWeights } from 'assets/styles'
import React from 'react'

export const DialogButton = ({ ...props }) => {
  return (
    <Button
      sx={{
        padding: '6px 10px',
        fontSize: fontSizes.fs_12,
        fontWeight: fontWeights.semibold,
        minWidth: '80px',
      }}
      {...props}
    />
  )
}
