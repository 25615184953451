import { Button } from '@mui/material'
import { fontWeights } from 'assets/styles'

export const OTPButton = ({ ...props }) => {
  return (
    <Button
      sx={{
        marginTop: '12px',
        height: '33px',
        borderRadius: '3px',
        fontSize: '12px',
        fontWeight: fontWeights.semibold,
      }}
      {...props}
    />
  )
}
