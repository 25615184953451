import {
  AppBar, IconButton, IconButtonProps, Toolbar, Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg'
import { colors, fontSizes, fontWeights } from 'assets/styles'
import * as React from 'react'

export interface HeaderProps {
  title?: string
  leftButton?: IconButtonProps
}
const ToolBarRoot = styled(Toolbar)`
  min-height: 66px;
  @media screen and (min-width: 600px) {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0px 24px;
    min-height: 66px;
  }
`
export const Header = ({ title, leftButton }: HeaderProps) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        background: colors.gradientOrange0deg,
      }}
    >
      <ToolBarRoot>
        <IconButton
          {...leftButton}
          size="large"
          edge="start"
          aria-label="menu"
          sx={{
            mr: 2,
            '&.MuiButtonBase-root': {
              boxShadow: '2px 2px 10px #737272, -2px -2px 10px #ffffff',
              background: 'linear-gradient( 0deg,#e5e8f7,#eaedf4)',
              borderRadius: '10px',
              padding: '4px',
              marginLeft: '0px',
            },
          }}
        >
          <LeftArrowIcon />
        </IconButton>
        <Typography
          variant="inherit"
          component="div"
          fontSize={fontSizes.fs_18}
          fontWeight={fontWeights.semibold}
          lineHeight="27px"
          sx={{ flexGrow: 1, margin: 'auto', textShadow: '2px 2px#5d5b5b' }}
        >
          {title}
        </Typography>
      </ToolBarRoot>
    </AppBar>
  )
}
