import { Box } from '@mui/system';
import { colors } from 'assets/styles';
import React from 'react';

export interface SubheaderTextProps {
  children?: React.ReactNode
  primary?: React.ReactNode
  secondary?: React.ReactNode
}
export const DialogHeader = ({
  children,
  primary,
  secondary,
}: SubheaderTextProps) => {
  return (
    <Box
      width="100%"
      display="flex"
      padding="8px 12px"
      bgcolor={colors.lightOrange}
      zIndex={100}
      boxSizing="border-box"
    >
      <Box minWidth="0" margin="0" flex="1">
        {primary && <>{primary}</>}
        {secondary && <>{secondary}</>}
        {children}
      </Box>
    </Box>
  )
}
