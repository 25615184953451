import { Box, BoxProps } from '@mui/system'
import React from 'react'
interface OtpContainerProps extends BoxProps {
  children?: React.ReactNode
}
export const OtpContainer = ({ children, ...props }: OtpContainerProps) => {
  return (
    <Box
      {...props}
      p="10px"
      width={'100%'}
      sx={{
        div: {
          margin: 'auto',
        },
        input: {
          width: '52px !important',
          height: '60px',
          borderRadius: '10px',
          border: '1px solid transparent',
          boxShadow: 4,
          fontSize: '30px',
        },
        'input::placeholder': {
          color: '#c2c2c2',
        },
      }}
    >
      {children}
    </Box>
  )
}
