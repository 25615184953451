import { Button, ButtonProps, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import { ReactComponent as DollarIcon } from 'assets/icons/dollar-sign.svg'

import { colors, fontWeights } from 'assets/styles'
import React from 'react'
const FooterContainer = styled(Box)`
  display: flex;
  @media screen and (min-width: 600px) {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0px 24px;
  }
`
interface FooterProps {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  footerButton?: ButtonProps
}
export const Footer = ({ primary, secondary, footerButton }: FooterProps) => {
  return (
    <Box
      width="100%"
      position="fixed"
      bottom={0}
      right={0}
      bgcolor="#fff"
      zIndex={100}
      p="12px 14px"
      sx={{ boxShadow: 4 }}
    >
      <FooterContainer>
        <Box display="block" flex="1">
          <Typography
            component="div"
            fontSize="12px"
            fontWeight={600}
            color="common.black"
            className="text-icon"
            marginTop="10px"
            whiteSpace={'pre-wrap'}
          >
            <>{primary}</>
            <>{secondary}</>
          </Typography>

          {/* <Typography
            component="div"
            fontSize="10px"
            fontWeight={400}
            color="#414141"
          >
            Price range
          </Typography> */}
        </Box>
        <Button
          {...footerButton}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '3px',
            textTransform: 'capitalize',
            fontSize: '12px',
            fontWeight: fontWeights.semibold,
          }}
        />
      </FooterContainer>
    </Box>
  )
}
