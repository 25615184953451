
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { navigationObservable } from '../helper/navigationSubjectHelper';
//to subscribe the navigation subject help on navigating
const NavigationProvider = () => {
  const history = useHistory()

  useEffect(() => {
    navigationObservable().subscribe(x => {
        if (x) {
          history.push(x);
        }
      })
  }, [])
  return (
    <>
     
    </>
  )
}
export default NavigationProvider