import {
  Dialog as MuiDIalog, DialogActions, DialogContent,
  DialogProps as MuiDialogProps
} from '@mui/material';
import * as React from 'react';

interface DialogProps extends MuiDialogProps {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
}
export const ContactDialog = ({ header, body, footer, ...props }: DialogProps) => {
  return (
    <MuiDIalog {...props} aria-labelledby="dialog-title">
      <DialogContent
        sx={{
          padding: '8px 12px !important',
        }}
      >
        {body}
      </DialogContent>
      {footer ? (
        <DialogActions
          sx={{
            padding: '8px 12px',
            justifyContent: "space-between"
          }}
        >
          {footer}
        </DialogActions>
      ) : null
      }

    </MuiDIalog >
  )
}
