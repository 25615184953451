import { ThemeProvider } from '@mui/material/styles'
import AppContext from 'containers/context/Context'
import { RootInitialState, RootReducer } from 'containers/context/Reducer'
import { Routes } from 'containers/routes'
import * as React from 'react'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { Theme } from 'theme/Theme'
import CssBaseline from '@mui/material/CssBaseline'
import { Button, Snackbar } from '@mui/material'
import { Spinner } from 'components'
import { DeleteCookies, GetCookies, SetCookies } from '../helper/cookieHelper'
import { client } from '../../client'
import { ApolloProvider } from '@apollo/react-hooks'
import 'react-phone-input-2/lib/material.css'
import 'assets/styles/style.scss'
import { useEffect, useState } from 'react'
import {
  useRefreshTokenLazyQuery,
  useLogoutLazyQuery,
} from '../../generated/graphql'
import { setAccessToken, getAccessToken } from '../../AccessToken'
import { loadingObservable } from 'containers/helper/loadingHelper'
import { snackBarObservable } from '../helper/snackBarSubjectHelper'
import { navigationObservable } from '../helper/navigationSubjectHelper'
import NavigationProvider from './NavigationProvider'

export default function App() {
  const [rootState, rootDispatch] = React.useReducer(
    RootReducer,
    RootInitialState
  )
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const accessToken = getAccessToken()
  const cookiesList = document.cookie.split('; ')
  const accCode = window.location.pathname.split('/')[1]
  const elCode = window.location.pathname.split('/')[2]

  const handleClose = (
    event?: Event | React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    rootDispatch({
      type: 'snackBar',
      payload: {
        open: false,
        message: '',
      },
    })
  }

  const [refreshToken] = useRefreshTokenLazyQuery({
    fetchPolicy: 'no-cache',
    client: client,
    onError: err => {
      setLoading(false)
    },
    onCompleted: data => {
      if (data?.refreshToken) {
        //it return the access token and set the access token
        setAccessToken(data.refreshToken)
        setLoading(false)
      }
    },
  })

  const [logoutQuery, { data, loading: logoutLoading, error }] =
    useLogoutLazyQuery({
      fetchPolicy: 'no-cache',
      onError: err => {
        DeleteCookies('ELAUNCH')
        setAccessToken('')
        DeleteCookies('accCode')
        DeleteCookies('elCode')
        history.push('/login')
      },
      onCompleted: data => {
        if (data) {
          DeleteCookies('ELAUNCH')
          setAccessToken('')
          DeleteCookies('accCode')
          DeleteCookies('elCode')
          history.push('/login')
        }
      },
    })

  useEffect(() => {
    if (
      window.location.pathname.split('/')[3] &&
      window.location.pathname.split('/')[3] === 'p'
    ) {
      setLoading(false)
    } else {
      refreshToken()
    }
    loadingObservable().subscribe(x => {
      rootDispatch({
        type: 'loading',
        payload: x,
      })
    })

    snackBarObservable().subscribe(x => {
      if (x) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: x,
          },
        })
      }
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  // useEffect(() => {
  //   if (cookiesList.length) {
  //     console.log('cookies change');

  //   }
  // }, [cookiesList])

  return (
    <AppContext.Provider value={{ rootState, rootDispatch }}>
      <ThemeProvider theme={Theme}>
        <ApolloProvider client={client}>
          {rootState?.loading ? <Spinner /> : null}
          <CssBaseline />
          <BrowserRouter basename={`${accCode}/${elCode}`}>
            <NavigationProvider />
            <Routes />

            <Snackbar
              open={rootState.snackBar?.open}
              autoHideDuration={2000}
              onClose={handleClose}
              message={rootState.snackBar?.message}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              action={
                <Button color="secondary" size="small" onClick={handleClose}>
                  DISMISS
                </Button>
              }
            />
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}
