import {
  DialogActions,
  Dialog as MuiDIalog,
  DialogTitle,
  DialogContent,
  Button,
  DialogProps as MuiDialogProps,
  ButtonProps,
} from '@mui/material'
import * as React from 'react'
import { DialogButton } from '../Button/DialogButton'

interface DialogProps extends MuiDialogProps {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: {
    buttons: ButtonProps[]
  }
}
export const Dialog = ({ header, body, footer, ...props }: DialogProps) => {
  return (
    <MuiDIalog {...props} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" sx={{ padding: '0px' }}>
        {header}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '8px 12px !important',
        }}
      >
        {body}
      </DialogContent>
      {footer ? (
        <DialogActions
          sx={{
            padding: '8px 12px ',
          }}
        >
          {footer?.buttons?.map((button, index) => (
            <DialogButton
              key={index}
              variant="contained"
              size="small"
              color={button.color || 'primary'}
              {...button}
            />
          ))}
        </DialogActions>
      ) : null}
    </MuiDIalog>
  )
}
