import { HeaderProps } from 'components/Header/Header'
import { LandingHeaderProps } from 'components/Header/LandingHeader'
import { Reducer } from 'react'
import { Action } from './Types'

export interface RootStateProps {
  header: {
    mode?: string
    landingHeaderProps?: LandingHeaderProps
    headerProps?: HeaderProps
  }
  snackBar?: {
    open?: boolean
    message?: string
  }
  loading?: boolean
}
export const RootInitialState: RootStateProps = {
  header: {
    mode: 'LandingHeader',
    landingHeaderProps: {},
    headerProps: {},
  },
  snackBar: {
    open: false,
    message: '',
  },
  loading: false,
}
export const RootReducer: Reducer<RootStateProps, Action> = (state, action) => {
  switch (action.type) {
    case 'reset':
      return RootInitialState
    default:
      return { ...state, [action.type]: action.payload }
  }
}
