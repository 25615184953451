import * as React from 'react'
import { styled } from '@mui/system'
import { BoxProps, Box } from '@mui/system'
import { BottomSpacing, TopSpacing } from 'assets/styles/types'

interface ContainerProps extends BoxProps {
  topSpacing?: TopSpacing | undefined
  bottomSpacing?: BottomSpacing | undefined
}

const mobileTopSpacing = (topSpacing: TopSpacing) => {
  switch (topSpacing) {
    case 'sm':
      return '38px'
    default:
      return 'unset'
  }
}
const desktopTopSpacing = (topSpacing: TopSpacing) => {
  switch (topSpacing) {
    case 'sm':
      return '102px'
    default:
      return '64px'
  }
}

const bottomSpacingStyle = (bottomSpacing: BottomSpacing) => {
  switch (bottomSpacing) {
    case 'sm':
      return '78px'
    case 'md':
      return '82px'
    case 'lg':
      return '150px'
    default:
      return ''
  }
}
// export const ContainerRoot = styled(Box)`
//   padding: 8px 6px;
//   margin-top: ${({ topSpacing }: ContainerProps) =>
//     mobileTopSpacing(topSpacing)};
//   @media screen and (min-width: 600px) {
//     margin-top: ${({ topSpacing }: ContainerProps) =>
//       desktopTopSpacing(topSpacing)};
//   }
// `
export const Container = ({
  bottomSpacing,
  topSpacing,
  ...props
}: ContainerProps) => {
  return (
    <Box
      padding="15px 20px"
      paddingBottom={bottomSpacingStyle(bottomSpacing)}
      marginTop={{
        xs: mobileTopSpacing(topSpacing),
        // sm: desktopTopSpacing(topSpacing),
      }}
      {...props}
    />
  )
}
