import {
  Button,
  ButtonProps,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as BuildAreaIcon } from 'assets/icons/build-area.svg'
import { ReactComponent as DollarIcon } from 'assets/icons/dollar-sign.svg'
import { fontWeights } from 'assets/styles'
import NumberFormat from 'react-number-format'
import { TranslationServices } from 'translateService'

interface DrawerProps extends MuiDrawerProps {
  contentSection?: {
    imagePreview?: string
    title?: string
    price?: string | number
    buildArea?: string | number
    type?: string
    direction?: string
  }
  footerButton?: ButtonProps[]
}
export const Drawer = ({
  footerButton,
  contentSection,
  ...props
}: DrawerProps) => {
  return (
    <MuiDrawer {...props} anchor={'bottom'}>
      {contentSection?.imagePreview && (
        <Box
          p="12px"
          width="100%"
          display="flex"
          justifyContent="center"
          sx={{
            img: {
              width: '100%',
              maxWidth: '800px',
            },
            maxWidth: { sm: '1200px' },
            margin: { sm: '0 auto' },
          }}
        >
          <img
            src={contentSection?.imagePreview}
            alt="floor-area"
            loading="lazy"
          />
        </Box>
      )}
      <Box
        p="12px"
        width="100%"
        borderTop="1px solid #E3E3E3"
        sx={{
          maxWidth: { sm: '1200px' },
          margin: { sm: '0 auto' },
        }}
      >
        <Typography
          component="div"
          fontSize="14px"
          fontWeight={fontWeights.semibold}
          color="black"
        >
          {contentSection?.title}
        </Typography>
        <Box width="100%" display="flex" mt="8px">
          <Typography
            component="div"
            width="50%"
            fontSize="12px"
            fontWeight={fontWeights.regular}
            color="black"
            className="text-icon"
          >
            <DollarIcon />{' '}
            <NumberFormat
              value={contentSection?.price}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </Typography>
          <Typography
            component="div"
            width="50%"
            fontSize="12px"
            fontWeight={fontWeights.regular}
            color="black"
            className="text-icon"
          >
            <BuildAreaIcon />{' '}
            <NumberFormat
              value={contentSection?.buildArea}
              displayType={'text'}
              thousandSeparator={true}
            />{' '}
            Sq.ft.
          </Typography>
        </Box>
        <Box width="100%" display="flex" mt="8px">
          <Typography
            component="div"
            width="50%"
            fontSize="12px"
            fontWeight={fontWeights.regular}
            color="black"
          >
            <Typography
              component="span"
              fontSize="12px"
              fontWeight={fontWeights.regular}
              color="#888888"
            >
              {TranslationServices.Translate('TYPE')}:
            </Typography>{' '}
            {contentSection?.type ?? '-'}
          </Typography>
          <Typography
            component="div"
            width="50%"
            fontSize="12px"
            fontWeight={fontWeights.regular}
            color="black"
          >
            <Typography
              component="span"
              fontSize="12px"
              fontWeight={fontWeights.regular}
              color="#888888"
            >
              {TranslationServices.Translate('DIRECTION')}:
            </Typography>{' '}
            {contentSection?.direction ?? '-'}
          </Typography>
        </Box>
        <Box
          width="100%"
          display="flex"
          mt="16px"
          sx={
            footerButton?.length > 1
              ? {
                  '.MuiButtonBase-root:first-child': {
                    maxWidth: '150px',
                    marginRight: '12px',
                  },
                }
              : null
          }
        >
          {footerButton?.map((button, buttonIndex) => (
            <Button
              key={buttonIndex}
              // variant="contained"

              {...button}
              sx={{
                margin: footerButton?.length === 1 ? '0 auto' : 'unset',
                borderRadius: '3px',
                textTransform: 'capitalize',
                fontSize: '12px',
                fontWeight: fontWeights.semibold,
              }}
            />
          ))}
        </Box>
      </Box>
    </MuiDrawer>
  )
}
