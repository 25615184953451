import {
  Card as MuiCard,
  CardContent, CardHeader, CardHeaderProps
} from '@mui/material'
import { colors } from 'assets/styles'
import React from 'react'

interface CardProps {
  header?: {
    title?: React.ReactNode
    subheader?: React.ReactNode
    action?: React.ReactNode
    props?: CardHeaderProps
  }
  children?: React.ReactNode
}
export const Card = ({ header, children }: CardProps) => {
  return (
    <MuiCard
      sx={{
        marginBottom: '4px',
        borderRadius: '3px',
        boxShadow: 'none',
        // boxShadow: '0 -19px 19px 2px rgba(0, 0, 0, 0.1)',
        background: 'none',
      }}
    >
      <CardHeader
        disableTypography
        sx={{
          border: '1px solid #dadee8',
          borderRadius: '3px',
          padding: '10px 12px',
          background: colors.lightOrange,
          '& .MuiIconButton-root': {
            padding: '0px',
            margin: 'auto',
          },
          '& .MuiSvgIcon-root': {
            width: '18px',
            height: '18px',
          },
          '& .MuiCardHeader-action': {
            margin: 'unset',
          },
        }}
        title={header?.title}
        subheader={header?.subheader}
        action={header?.action}
        {...header?.props}
      />
      <CardContent
        sx={{
          padding: '0',
          display: 'flex',
          flexWrap: 'wrap',
          ':last-child': {
            paddingBottom: '8px',
          },
        }}
      >
        {children}
      </CardContent>
    </MuiCard>
  )
}
