import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { fontSizes, fontWeights } from 'assets/styles'
import { ReactNode } from 'react'
export const AuthSection = styled('div')`
  background-size: cover !important;
  background-color: rgba(0, 0, 0, 0.4);
  background: ${({ backgroundimage }: AuthContainerProps) =>
    backgroundimage
      ? `url("${backgroundimage}") no-repeat center center fixed`
      : '#212121'};
  height: 100vh;
  display: flex;
  align-items: center;
`
export const AuthContentSection = styled('div')`
  max-width: 400px;
  min-width: 280px;
  margin: 0 auto;
  width: 90vw;
  padding: 20px 14px;
  border-radius: 5px;
  background-color: #fff;
  height: fit-content;
  border-top: 5px solid;
  border-image: linear-gradient(
      to right,
      #0db14b 25%,
      #d80b8c 25%,
      #d80b8c 50%,
      #00a6e9 50%,
      #00a6e9 75%,
      #f58220 75%
    )
    5;

  // Title
  .page-title {
    font-size: ${fontSizes.fs_18};
    font-weight: ${fontWeights.bold};
    line-height: 27px;
    text-align: center;
    display: block;
    margin: 10px 0px 0px;
    color: black;
  }
  // SubTitle
  .page-subtitle {
    color: #515151;
    display: block;
    /* margin: 10px 0px 0px; */
    text-align: center;
    white-space: break-spaces;
    line-height: 18px;
    font-size: ${fontSizes.fs_10};
    font-weight: ${fontWeights.medium};
  }
  //forgot-password
  .forget-password {
    color: #6a6a6a;
    font-size: ${fontSizes.fs_12};
    font-weight: ${fontWeights.regular};
    line-height: 16px;
    text-align: right;
    display: block;
    cursor: pointer;
    flex: 1;
  }
  // form content
  .form-content {
    margin-top: 15px;
    .MuiFormControl-root {
      width: 100%;
    }
    .forget-password-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      /* .MuiCheckbox-root {
        color: rgba(255, 255, 255, 0.54);
      }
    */
      .MuiFormControlLabel-label {
        color: #6a6a6a;
        font-size: ${fontSizes.fs_12};
        font-weight: ${fontWeights.regular};
      }
      .MuiButtonBase-root {
        padding: 9px;
      }
    }
  }
`
const Logo = styled('img')`
  width: 100%;
  max-width: 100px;
  display: block;
  margin: 0 auto;
`
export interface AuthContainerProps {
  /** standard children prop: accepts any valid React Node  */
  children?: ReactNode
  /** Background image */
  backgroundimage?: string
  /**Logo Image */
  logo?: string
  /** Title value*/
  title?: string
  /** Subtitle value */
  subTitle?: string
}
export const AuthContainer = ({
  title,
  children,
  logo,
  subTitle,
  ...props
}: AuthContainerProps) => {
  const LogoSection = logo && <Logo src={logo} alt="logo" loading="lazy" />

  const SubTitleSection = subTitle && (
    <Typography component="span" variant="inherit" className="page-subtitle">
      {subTitle}
    </Typography>
  )
  return (
    <AuthSection {...props}>
      <AuthContentSection>
        {LogoSection}
        <Typography component="span" className="page-title" variant="inherit">
          {title}
        </Typography>
        {SubTitleSection}
        {children}
      </AuthContentSection>
    </AuthSection>
  )
}
